var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-col", [
            _c(
              "div",
              [
                _vm.api.isLoading
                  ? _c("v-skeleton-loader", {
                      ref: "skeleton",
                      attrs: { type: "table" },
                    })
                  : _vm._e(),
                this.companiesRenewal != null
                  ? _c("v-data-table", {
                      staticClass: "elevation-1",
                      attrs: {
                        search: _vm.searchCompanies,
                        items: this.filteredCompanies,
                        headers: this.companiesRenewalHeader,
                        dense: "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "item.company_name",
                            fn: function (ref) {
                              var item = ref.item
                              return [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "PageCompaniesDetail",
                                        params: { id: item.company_id },
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(item.company_name) + " "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                          {
                            key: "top",
                            fn: function () {
                              return [
                                _c(
                                  "v-toolbar",
                                  {
                                    attrs: {
                                      width: "auto",
                                      height: "auto",
                                      color: "py-3",
                                      flat: "",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c(
                                          "v-col",
                                          { staticClass: "mt-3 mx-3" },
                                          [
                                            _c(
                                              "v-row",
                                              { staticClass: "mb-3" },
                                              [
                                                _c(
                                                  "v-toolbar-title",
                                                  {
                                                    staticClass:
                                                      "font-weight-bold text-h4 my-3",
                                                  },
                                                  [_vm._v(" Renewal ")]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  [
                                                    _c("v-select", {
                                                      attrs: {
                                                        outlined: "",
                                                        dense: "",
                                                        clearable: "",
                                                        items:
                                                          _vm.renewalStatusOption,
                                                        label:
                                                          "Filter renewal status...",
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.filterRenewalStatus,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.renewalStatusFiltered,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.renewalStatusFiltered =
                                                            $$v
                                                        },
                                                        expression:
                                                          "renewalStatusFiltered",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        dense: "",
                                                        outlined: "",
                                                        label: "Search...",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.searchCompanies,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.searchCompanies =
                                                            $$v
                                                        },
                                                        expression:
                                                          "searchCompanies",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "item.Index",
                            fn: function (ref) {
                              var item = ref.item
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.companiesRenewal.indexOf(item) + 1
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        247545630
                      ),
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }